<template>
  <div class="mb-3 card">

    <div>
      <v-tabs fixed-tabs v-model="tab">
        <v-tab key="basic">
          {{ $t('erp.lang_baseData') }}
        </v-tab>
        <v-tab key="picture">{{ $t('erp.lang_productImage') }}</v-tab>
        <v-tab key="wareattributes">{{ $t('erp.lang_warecreate_wareattr') }}</v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item key="basic">
            <v-container fluid>
              <v-layout>
                <v-flex md4 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="form.ware_ID"
                                :label="$t('erp.lang_ware_create_id')"
                                @change="checkEAN"
                                @blur="checkEAN"
                                :rules="[v => !!v  , this.duplicatedEAN || $t('erp.lang_barcodeAlreadyTaken')]"
                                :loading="loadingCheck"
                                autocomplete="off"
                                required
                  >
                    <template slot="append">
                      <font-awesome-icon class="linkCursor" @click="GetWareId" :icon="['fal', 'barcode']" size="1x"/>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex md4 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="form.ware_name"
                                :label="$t('erp.lang_itemname')"
                                autocomplete="off"
                                required
                  ></v-text-field>
                </v-flex>
                <v-flex md4 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="form.ware_internalNo"
                                :label="$t('erp.internal_wareNo')"
                                autocomplete="off"
                                required
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex md4 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="form.ware_buyprice"
                                :label="$t('erp.lang_netPurchasePricePerUnit')"
                                autocomplete="off"
                  ></v-text-field>
                </v-flex>
                <v-flex md4 mr-2 ml-2>
                  <date-picker :label="$t('erp.lang_iteminstocksince')"
                               v-model="form.editItemSinceExistent"/>
                </v-flex>
                <v-flex md4 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="form.editItemStorageQTY"
                                :label="$t('erp.lang_quantityUnit')"
                                autocomplete="off"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex md4 mr-2 ml-2>
                </v-flex>
                <v-flex md4 mr-2 ml-2>
                  <date-picker :label="$t('erp.lang_itemnextstocktaking')"
                               v-model="form.editItemNextStocktaking"/>
                </v-flex>
                <v-flex md4 mr-2 ml-2>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
          <v-tab-item key="picture">
            <v-container fluid>
              <v-layout>
                <v-flex md12 mr-2 ml-2>
                  <image-cropper v-model="form.productImage"/>
                </v-flex>
              </v-layout>

            </v-container>
          </v-tab-item>
          <v-tab-item key="wareattributes">
            <v-container fluid>
              <v-layout row>
                <v-flex xs12 md4 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="form.biozertnr"
                                :label="$t('erp.lang_warecreate_biozertno')"
                                autocomplete="off"
                  ></v-text-field>
                </v-flex>
                <v-flex xs8 md4 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="form.BioText"
                                :label="$t('erp.lang_warecreate_biotext')"
                                autocomplete="off"
                  ></v-text-field>
                </v-flex>
                <v-flex xs3 md3 mr-2 ml-2>
                  <v-checkbox
                      v-model="form.bio_yes_no"
                      :label="$t('erp.lang_warecreate_bioware')"
                      autocomplete="off"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex>
                  <label>{{ $t("erp.lang_Zusatzstoffe") }}</label>
                </v-flex>
              </v-layout>


              <v-layout wrap>
                <v-flex md4 xs12 v-for="(additive, index) in additives" :key="'z-' + index">
                  <v-checkbox
                      style="margin-top:0px !important;"
                      :hide-details=true
                      v-model="additives[index].selected"
                      :label="additive.label"
                      autocomplete="off"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <br>
              <v-layout>
                <v-flex>
                  <label>{{ $t("erp.lang_Allergene") }}</label>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex md4 xs12 v-for="(allergen, index) in allergene" :key="'a-' + index">
                  <v-checkbox
                      style="margin-top:0px !important;"
                      :hide-details=true
                      v-model="allergene[index].selected"
                      :label="allergen.label"
                      autocomplete="off"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>

      <v-container fluid :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
        <v-layout>
          <v-flex class="text-right">
            <v-btn text color="error" @click="resetData">{{ $t('generic.lang_prev') }}</v-btn>
            <v-btn color="error"
                   :disabled="this.loading"
                   v-if="(id || parseInt(id) > 0) && this.$store.getters['permissions/checkPermission']('ware_delete')"
                   @click="deleteData">{{
                $t('generic.lang_delete')
              }}
            </v-btn>
            <v-btn color="success"
                   :loading="this.loading"
                   :disabled="(!form.ware_ID || form.ware_ID.length < 1) || (!form.ware_name || form.ware_name.length < 1) || (!form.ware_internalNo || form.ware_internalNo.length < 1) || this.loading"
                   @click="updateData">
              <span v-if="(!id || parseInt(id) < 1)">{{ $t('generic.lang_add') }}</span>
              <span v-else>{{ $t('generic.lang_edit') }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>


    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<style>
.v-btn--floating {
  width: 30px !important;
  height: 30px !important;
}

.dataTables_filter {
  display: none;
}

.extra-padding .v-input {
  margin-top: 10px;
}

.extra-padding .v-input input {
  padding-bottom: 12px;
  max-height: 42px;
}

.narrow-list .v-input {
  margin-top: 5px !important;
}

.narrow-list .v-input__slot {
  margin-bottom: 0px !important;
}

.narrow-list label {
  margin-bottom: 0px !important;
}
</style>

<script>
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import Datatable from "../../datatable/Datatable";
import ImageCropper from "../../common/imagecropper";
import DatePicker from "../../common/datepicker";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faBarcode} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import mixin from "../../../mixins/KeyboardMixIns";


library.add(
    faBarcode
);

export default {
  components: {
    Datatable,
    ImageCropper,
    DatePicker,
    'font-awesome-icon': FontAwesomeIcon
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      id: this.$route.params.id ? this.$route.params.id : null,
      loading: false,
      loadingCheck: false,
      duplicatedEAN: true,
      form: {
        ware_ID: null,
        ware_name: null,
        ware_internalNo: null,
        ware_buyprice: null,
        editItemSinceExistent: null,
        editItemStorageQTY: null,
        editItemNextStocktaking: null,
        biozertnr: null,
        BioText: null,
        bio_yes_no: null,
        productImage: null,
        zusaetze: [],
        allergene: []
      },
      additives: [],
      allergene: [],
      tab: 0,
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80
        },
        {text: this.$t('erp.lang_tax_name'), value: 1},
        {text: this.$t('erp.lang_tax_value'), value: 2},
        {text: this.$t('erp.lang_standard'), value: 3}
      ],
      excelColumns: [

        {
          label: "ID",
          field: 'id',
        },
        {
          label: this.$t('erp.lang_tax_name'),
          field: 1,
        },
        {
          label: this.$t('erp.lang_tax_value'),
          field: 2,
        },
        {
          label: this.$t('erp.lang_standard'),
          field: 3,
        }
      ],
    }
  },

  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },

  methods: {
    updateData: function () {
      let self = this;
      let url = ENDPOINTS.ERP.INVENTORYITEM.CREATE

      let formData = new FormData();

      if (self.id && self.id > 0) {
        url = ENDPOINTS.ERP.INVENTORYITEM.UPDATE
        formData.append("editItemID", parseInt(self.id))
      } else {
        self.form.editItemID = null;
      }

      formData.append("ware_ID", self.form.ware_ID)
      formData.append("ware_name", self.form.ware_name)
      formData.append("ware_internalNo", self.form.ware_internalNo)
      formData.append("ware_buypriceNet", self.form.ware_buyprice)
      formData.append("editItemSinceExistent", self.form.editItemSinceExistent)
      formData.append("editItemStorageQTY", self.form.editItemStorageQTY)
      formData.append("editItemNextStocktaking", self.form.editItemNextStocktaking)
      formData.append("biozertnr", self.form.biozertnr)
      formData.append("BioText", self.form.BioText)
      formData.append("bio_yes_no", self.form.bio_yes_no)
      formData.append("productImage", self.form.productImage)

      let selected = self.additives.filter(z => z.selected == true)
      if (selected && selected.length > 0) {
        for (let i = 0; i < selected.length; i++) {
          formData.append("zusaetze[]", selected[i].key)
        }
      }

      selected = self.allergene.filter(z => z.selected == true)
      if (selected && selected.length > 0) {
        for (let i = 0; i < selected.length; i++) {
          formData.append("allergene[]", selected[i].key)
        }
      }

      this.loading = true;
      this.axios.post(url, formData).then((res) => {
        if (res.data.success === 'SUCCESS' || res.data.success === true || (res.data.inventoryItemID && parseInt(res.data.inventoryItemID) > 0)) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          self.resetData();
        } else if (res.data.status === 'CHECK ERROR' || res.data.status === 'duplicateItem') {

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_eanCheckFailed'),
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => this.loading = false);
    },
    checkEAN() {
      this.duplicatedEAN = true;

      if (this.form.ware_ID.length === 0)
        return;

      this.loadingCheck = true;

      //PREPARE POST PAYLOAD
      let payload = {
        barcode: this.form.ware_ID
      };

      if (this.id !== null)
        payload.itemId = this.id;

      this.axios.post(ENDPOINTS.ERP.ITEM.BARCODES.CHECKDUPLICATEEAN, payload).then((res) => {
        this.duplicatedEAN = res.data.success;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingCheck = false;
      })
    },
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('accounting.lang_deleteTaxRate'),
        text: this.$t('accounting.lang_deleteTaxRateText'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.ERP.INVENTORYITEM.DELETE, {
            deleteItemID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_inventoryItemsDeleted'),
                color: "success"
              });

              self.resetData();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetData() {
      this.$router.push({name: "erp.baseData.inventoryItems"});
    },
    entryEdit() {
      let self = this;

      if (!(self.id && self.id > 0)) {
        return;
      }

      self.axios.post(ENDPOINTS.ERP.INVENTORYITEM.GET, {
        editItemID: parseInt(self.id)
      }).then((res) => {
        if (res.status === 200) {
          self.form.ware_ID = res.data.formfillData.textFields.ware_ID;
          self.form.ware_name = res.data.formfillData.textFields.ware_name;
          self.form.ware_internalNo = res.data.formfillData.textFields.ware_internalNo;
          self.form.ware_buyprice = res.data.formfillData.textFields.ware_buypriceNet;
          self.form.editItemSinceExistent = res.data.formfillData.textFields.editItemSinceExistent;
          self.form.editItemNextStocktaking = res.data.formfillData.textFields.editItemNextStocktaking;
          self.form.editItemStorageQTY = res.data.formfillData.textFields.editItemStorageQTY;
          self.form.biozertnr = res.data.formfillData.textFields.biozertnr;
          self.form.BioText = res.data.formfillData.textFields.BioText;
          self.form.bio_yes_no = res.data.formfillData.textFields.bio_yes_no;
          self.form.productImage = res.data.imageData[1];
          self.form.zusaetze = [];
          self.form.allergene = [];
        } else {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    GetWareId() {
      let self = this;

      self.axios.post(ENDPOINTS.ERP.INVENTORYITEM.EAN.CREATE).then((res) => {
        if (res.status === 200) {
          self.form.ware_ID = res.data && res.data != null && res.data.length > 0 ? res.data[0] : self.form.ware_ID;
        } else {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    loadData() {
      let self = this;

      self.axios.get(ENDPOINTS.ERP.INVENTORYITEM.GETDATA).then((res) => {
        self.allergene = [];
        self.additives = [];
        if (res.status === 200) {
          if (res.data.allergensArr && res.data.allergensArr.length > 0) {
            let total = res.data.allergensArr.length;
            for (let i = 0; i < total; i++) {
              let allergen = res.data.allergensArr[i]
              allergen = {"key": allergen[0], "label": allergen[1], "selected": false};
              self.allergene.push(allergen)
            }
          }

          if (res.data.additivesArr && res.data.additivesArr.length > 0) {
            let total = res.data.additivesArr.length;
            for (let i = 0; i < total; i++) {
              let additive = res.data.additivesArr[i]
              additive = {"key": additive[0], "label": additive[1], "selected": false};
              self.additives.push(additive)
            }
          }
        } else {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    entryDelete(entry) {
      this.id = entry.id;
      this.deleteData();
    },
    entriesDelete(entries) {
      this.deleteData(entries);
    },
  },
  mounted() {
    this.entryEdit();
    this.loadData();
  },
}
</script>
